<template>
  <div class="main-lander">
    <section class="hero">
      <div>
        <img
          class="poster"
          src="@/assets/img/bamfposter.jpg"
          alt="Bacchanalia Lineup"
        />
        <div class="actions">
          <a
            class="action-highlight action-item"
            target="_blank"
            href="https://www.simpletix.com/e/bacchanalia-2022-tickets-111168"
            @click="ticketLinkClick"
          >
            GET TICKETS
          </a>
          <div class="artists-apply">
            <a class="action-standard action-item" href="#schedule">
              SCHEDULE
            </a>
            <a
              class="action-standard action-item ml-2"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSd7VYJ_swwPqn4mPgdPqYidu7_vaHDl1BBCSyYlLFgOpsWJCA/viewform?usp=sf_link"
            >
              VOLUNTEER
            </a>
          </div>
        </div>
      </div>
    </section>
    <img class="w100" src="@/assets/img/Breaker.jpeg" alt="Page Break" />
    <img
      v-if="false"
      class="w100"
      src="@/assets/img/Breaker.jpeg"
      alt="Page Break"
    />
    <div class="about">
      <div class="about__content">
        <div class="title">WHAT?</div>
        <p>
          Bacchanalia Arts & Music Festival is a brand new showcase of talent
          from around the state and around the globe. From September 30th to
          October 2nd, 2022, the streets of Troy, NY will be filled with
          vibrance and volume as the inaugural year of Bacchanalia transforms
          the city into an entertainment paradise. That means:
        </p>
        <p class="mt-5">- Live performances in venues across the city</p>
        <p class="mt-2">- Main outdoor stage with national headlining acts</p>
        <p class="mt-2">- Food trucks and pop-ups</p>
        <p class="mt-2">- Retail vendors</p>
        <p class="mt-2">- Art exhibits + live art</p>
        <p class="mt-2">
          Stay tuned for the performance schedule, giveaways, artist features,
          and more!
        </p>
        <p class="mt-4">- The BAMF Team</p>
      </div>
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div id="schedule" class="venues schedule pt-5 pb-5">
      <div class="title mt-5 mb-6">SCHEDULE</div>
      <p class="cal">Click a performance to add it to Google Calendar!</p>
      <div class="days">
        <div v-for="day in festDays" :key="day.venue" class="card mt-3 mb-3">
          <header @click="day.expanded = !day.expanded" class="card-header">
            <p class="archivo card-header-title">{{ day.day }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.16108 10.0731C4.45387 9.2649 5.02785 8 6.1018 8H17.898C18.972 8 19.5459 9.2649 18.8388 10.0731L13.3169 16.3838C12.6197 17.1806 11.3801 17.1806 10.6829 16.3838L5.16108 10.0731ZM6.65274 9.5L11.8118 15.396C11.9114 15.5099 12.0885 15.5099 12.1881 15.396L17.3471 9.5H6.65274Z"
                    fill="#212121"
                  />
                </svg>
              </span>
            </button>
          </header>
          <div v-if="day.expanded" class="card-content">
            <article class="panel is-primary">
              <div class="select-box pb-3">
                <div class="select">
                  <select v-model="day.selectedVenue">
                    <option
                      v-for="venue in day.venues"
                      :key="venue"
                      class="archivo"
                    >
                      {{ venue }}
                    </option>
                  </select>
                </div>
              </div>
              <a
                v-for="performance in getPerformances(
                  day.day,
                  day.selectedVenue
                )"
                :key="performance.name"
                class="panel-block"
                :href="getCalendarLink(performance)"
                target="_blank"
                >{{ performance.time }} - {{ performance.name }}</a
              >
            </article>
          </div>
        </div>
      </div>
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div id="map" class="venues map pt-5 pb-5">
      <div class="title mt-5 mb-6">FESTIVAL MAP</div>
      <img class="mapimg" src="@/assets/img/map.jpeg" alt="BAMF 2022 Map" />
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div class="venues pt-5 pb-5">
      <div class="title mt-5 mb-6">VENUES</div>
      <div class="columns">
        <div class="column">
          <div class="column__content">
            <a href="https://whiskeypickletroy.com" target="_blank">
              <img
                src="@/assets/img/venues/wp-logo-300.png"
                alt="Whiskey Pickle"
              />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <a href="https://www.nofuntroy.com/" target="_blank">
              <img src="@/assets/img/venues/nofun.png" alt="No Fun" />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <a href="https://www.rareformbrewing.com/" target="_blank">
              <img src="@/assets/img/venues/rareform.png" alt="Rare Form" />
            </a>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="column__content">
            <a href="https://www.brownsbrewing.com/" target="_blank">
              <img
                src="@/assets/img/venues/browns-white.png"
                alt="Brown's Brewing"
              />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <a
              href="https://www.instagram.com/eldorado_bar_troy/"
              target="_blank"
            >
              <img src="@/assets/img/venues/eldo.jpeg" alt="El Dorado" />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <a href="https://www.getrucked.com/" target="_blank">
              <img src="@/assets/img/venues/ruck.png" alt="The Ruck" />
            </a>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="column__content">
            <a href="http://slidindirty.com/" target="_blank">
              <img src="@/assets/img/venues/slidin.png" alt="Slidin Dirty" />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <a href="https://franklinalley.com/" target="_blank">
              <img
                src="@/assets/img/venues/fasc.png"
                alt="Franklin Alley Social Club"
              />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="column__content">
            <!-- <a href="https://www.instagram.com/thebradleybar" target="_blank">
              <img src="@/assets/img/venues/bradley.png" alt="The Bradley" />
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div class="venues pt-5 pb-5">
      <div class="title mt-5 mb-6">SPONSORS</div>
      <div class="column">
        <div class="column__content vertical">
          <a href="https://radioradiox.com/" target="_blank">
            <img src="@/assets/img/sponsors/radiox.png" alt="RadioRadioX" />
          </a>
          <div class="sponsor-callout">El Dorado Stage & Media Sponsor</div>
        </div>
      </div>
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div class="venues map pt-5 pb-5 pl-1 pr-1">
      <div class="title mt-5 mb-6">ART DIRECTORS</div>
      <a
        class="director"
        href="https://www.instagram.com/visualilypleasing/"
        target="_blank"
        >Lily Buckley</a
      >
      <a
        class="director"
        href="https://www.instagram.com/obliviousthedeep/"
        target="_blank"
        >Olivia Deep</a
      >
      <a
        class="director"
        href="https://www.instagram.com/showerpanther/"
        target="_blank"
        >Sean McLaughlin</a
      >
    </div>
    <img class="w100" src="@/assets/img/Breaker.png" alt="Page Break" />
    <div id="signup" class="two-column__column">
      <img class="melted-image" src="@/assets/img/melted.jpg" alt="Melted" />
      <div class="melted-content">
        <div class="not-signed-up" v-if="!hasSubmitted">
          <p>Stay up to date on the lineup, tickets, schedule, and more!</p>
          <div class="signup-input mt-4">
            <input
              id="signup-input"
              ref="signup"
              class="input"
              type="text"
              v-model="email"
            />
            <button @click="submitEmail">SUBMIT</button>
          </div>
        </div>
        <div v-else class="thanks">
          <template v-if="emailSubmitError"
            >There was an issue signing up - please reload the page and try
            again.</template
          >
          <template v-else
            >Thanks! We'll keep you in the loop - stay tuned!</template
          >
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Bacchanalia Arts & Music Festival | Copyright 2022 |
          <a href="mailto:info@bacchanalia.party">info@bacchanalia.party</a>
        </p>
        <p>
          <a href="https://instagram.com/bamf.party" target="_blank"
            >Instagram</a
          >
          |
          <a href="https://facebook.com/bamf.party" target="_blank">Facebook</a>
          |
          <a href="https://twitter.com/bamf_party" target="_blank">Twitter</a> |
          <a
            href="https://docs.google.com/presentation/d/1uEItyqB0rI9qM0y4vZIBY-7eseLzdSLwSBtb3Usczvo/edit?usp=sharing"
            >Public Meeting Slides</a
          >
          |
          <a
            href="https://docs.google.com/document/d/1_pjA9AsFAyuAWV7AvGdZLGL-lrU54igYVqsU_9Z6aa8/edit?usp=sharing"
            target="_blank"
            >FAQ</a
          >
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { VueMasonryPlugin } from "vue-masonry";
import axios from "axios";
import artists from "@/assets/json/artists2.json";
const mailingListUrl =
  "https://us-east4-bacchanalia.cloudfunctions.net/join-list";
Vue.use(VueMasonryPlugin);

const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return {
    hours,
    minutes,
  };
};

export default {
  data() {
    return {
      email: "",
      isSubmittingEmail: false,
      emailSubmitError: false,
      hasSubmitted: false,
      artists,
      tiles: [
        {
          id: 1,
          image: "@/assets/img/mosaic/1.jpg",
        },
        {
          id: 2,
          image: "/img/mosaic/2.jpg",
        },
      ],
      festDays: [
        {
          day: "FRIDAY",
          venues: [
            "Main Stage",
            "No Fun",
            "El Dorado",
            "Rare Form",
            "Pickle Back",
            "Slidin' Dirty",
          ],
          selectedVenue: "Main Stage",
          expanded: true,
        },
        {
          day: "SATURDAY",
          venues: [
            "Main Stage",
            "No Fun",
            "El Dorado",
            "Rare Form",
            "Pickle Back",
            "Brown's Tap Room",
            "Brown's Malt Room",
            "Franklin Alley Social Club",
          ],
          selectedVenue: "Main Stage",
          expanded: false,
        },
        {
          day: "SUNDAY",
          venues: [
            "Main Stage",
            "No Fun",
            "El Dorado",
            "Rare Form",
            "Pickle Back",
          ],
          selectedVenue: "Main Stage",
          expanded: false,
        },
      ],
    };
  },
  computed: {
    performancesByDateVenue() {
      const performances = {
        FRIDAY: {},
        SATURDAY: {},
        SUNDAY: {},
      };
      this.artists.forEach((artist) => {
        const timeString = `December 17, 2021 ${artist.time}`;
        artist.dateTime = new Date(timeString);
        if (artist.date !== "#N/A") {
          if (performances[artist.date][artist.venue]) {
            performances[artist.date][artist.venue].push(artist);
          } else {
            performances[artist.date][artist.venue] = [artist];
          }
        }
      });
      // sort
      return performances;
    },
  },
  methods: {
    getPerformances(date, venue) {
      const performances = this.performancesByDateVenue[date][venue];
      const sortedPerformances = performances.sort(
        (a, b) => b.dateTime - a.dateTime
      );
      const reallySorted = [];
      sortedPerformances.forEach((performance) => {
        if (performance.time === "12:00 AM") {
          reallySorted.unshift(performance);
        } else {
          reallySorted.push(performance);
        }
      });
      return reallySorted;
    },
    getCalendarLink(performance) {
      let day = "30";
      let monthIndex = 8;
      if (performance.date === "SATURDAY") {
        day = "1";
        monthIndex = 9;
      } else if (performance.date === "SUNDAY") {
        day = "2";
        monthIndex = 9;
      }
      const { hours, minutes } = convertTime12to24(performance.time);
      let dateFrom =
        new Date(2022, monthIndex, day, hours, minutes)
          .toISOString()
          .split(".")[0] + "Z";
      let dateTo =
        new Date(2022, monthIndex, day, hours, minutes + 30)
          .toISOString()
          .split(".")[0] + "Z";
      dateFrom = dateFrom.replace(/[^a-z0-9]/gi, "");
      dateTo = dateTo.replace(/[^a-z0-9]/gi, "");
      const description = `Location: ${performance.venue}`;
      const title = `${performance.name} @ BAMF`;
      return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&dates=${dateFrom}/${dateTo}&location=Troy, NY, USA`;
    },
    goToSignup() {
      window.location.href = "#signup";
      this.$refs.signup.focus();
    },
    ticketLinkClick() {
      window.gtag("event", "conversion", {
        send_to: "AW-10955199658/rQsQCMO2j9ADEKqp7Oco",
      });
    },
    async submitEmail() {
      this.isSubmittingEmail = true;
      this.emailSubmitError = false;
      const params = new URLSearchParams();
      params.append("email", this.email);
      try {
        await axios.post(mailingListUrl, params, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        window.fbq("track", "CompleteRegistration");
        this.hasSubmitted = true;
      } catch (e) {
        console.log(e);
        this.emailSubmitError = true;
      }
      this.hasSubmitted = true;
      this.isSubmittingEmail = false;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-lander {
  img {
    display: block;
  }
  &__eye-logo {
    display: block;
    width: 25vw;
    max-width: 250px;
    margin: 0 auto;
  }
}
.archivo {
  font-family: "ArchivoBlack", Impact, sans-serif;
  color: var(--color-dark-grey);
}
.action-item {
  font-family: "ArchivoBlack", Impact, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #000;
  cursor: pointer;
}
.double-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.25rem;
}
.actions {
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
}
.action-highlight {
  background-color: var(--color-yellow);
  font-size: 1.5em;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}
.action-standard {
  background-color: var(--color-tan);
  font-size: 1.25em;
  padding: 0.5rem;
}
.w50 {
  width: 50%;
}
.w100 {
  width: 100%;
}
.dates {
  font-family: "ArchivoBlack", Impact, sans-serif;
  font-size: 1.5em;
  color: var(--color-tan);
}
.hero-logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
.poster {
  padding: 1rem 0;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.main-title {
  font-family: "VivaKaiva, Helvetica, sans-serif";
}
.hero {
  background: url("../assets/img/HeroBackground1920.jpeg");
  background-size: cover;
  padding: 0 1rem;
}
.item {
  width: 25%;
}
.mosaic {
  &__big-desktop {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  &__bd-tl {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  &__bd-br {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-top: -232px;
  }
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  background-color: var(--color-offwhite);
  color: var(--color-dark-grey);
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;

  &__content {
    width: 100%;
    max-width: 600px;
    text-align: left;
    margin: 0 auto;
  }
}
.two-column {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
#signup {
  position: relative;
  img {
    width: 100%;
  }
}
#venues {
  background-color: var(--color-tan);
}
.melted-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
  color: #fff;
  text-align: left;
  font-size: 1.25em;
  line-height: 1.2em;
}
.signup-input {
  display: flex;
  input {
    width: 100%;
    flex: 1;
    border-radius: 0px;
  }
  button {
    flex: 0 0 100px;
    background-color: var(--color-yellow);
    border: none;
    font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
    cursor: pointer;
    margin-left: 4px;
  }
}
#venues {
  text-align: left;
  padding: 1rem;
  p {
    font-family: "ArchivoBlack", Impact, sans-serif;
  }
}
.venue-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.venues {
  .title {
    font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
    color: var(--color-tan);
  }
}
.column__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1rem;
  img {
    display: block;
    max-width: 250px;
    max-height: 250px;
  }
}
.vertical {
  flex-direction: column;
}
.sponsor-callout {
  color: #fff;
  margin-top: 0.5rem;
}
.schedule {
  padding: 1rem;
}
#schedule {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.select-box {
  display: flex;
  border-bottom: 1px solid #000;
}
.card-header {
  cursor: pointer;
}
.artists-apply {
  display: grid;
  grid-template-columns: 50% 50%;
}
.mapimg {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
.cal {
  color: var(--color-offwhite);
}
.director {
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
  font-size: 1.75em;
  color: var(--color-yellow);
  display: block;
  margin-top: 1rem;
}
</style>
