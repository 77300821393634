import Vue from "vue";
import VueRouter from "vue-router";
// import EmailSplashPage from "../components/EmailSplashPage.vue";
import MainLander from "../components/MainLander.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainLander",
    component: MainLander,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/musicians",
    name: "Musicians",
    component: () =>
      import(
        /* webpackChunkName: "musicians" */ "../components/MusicArtistApp.vue"
      ),
  },
  {
    path: "/applications",
    name: "Applications",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../views/FestivalApps.vue"
      ),
  },
  {
    path: "/artists",
    name: "artists",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../components/MusicalArtists.vue"
      ),
  },
  {
    path: "/artist/:id",
    name: "artist",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../components/ArtistProfile.vue"
      ),
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  routes,
});

export default router;
